<template>
  <div class="supplier-page-container">
    <!-- 排行榜 表格数据区域 -->
    <!-- <div class="ranking-data">
            <div class="supplier-ranking">
                <div class="title-box">
                    <p>供应信息</p>
                    <div class="search-bar">
                        <tree-select
							placeholder="品牌"
							v-model="filter.brandId"
							:clearable="false"
							:options="brandList"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							:key="filter.brandId"
							@select="(node) => treeChange(node, 'brandId')"
							:flatten-search-results="true"/>
                        <tree-select
							placeholder="系列" 
							:clearable="false"
							:options="seriesList"
							v-model="filter.seriesId"
							:key="filter.seriesId"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							@select="(node) => treeChange(node, 'seriesId')"
							:flatten-search-results="true"/>
                        <el-date-picker
                            class="date-picker-box"
                            v-model="filter.queryDate"
                            type="datetimerange"
							:clearable="false"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="changeDate"></el-date-picker>
                    </div>
                </div>
                <el-table :data="supplierData" height="calc(100% - 42px)">
                    <el-table-column label="序号" type="index"></el-table-column>
                    <el-table-column label="供应商产品" prop="productName"></el-table-column>
                    <el-table-column label="品牌" prop="brandName"></el-table-column>
                    <el-table-column label="系列" prop="seriesName"></el-table-column>
                    <el-table-column label="时间" prop="createTime"></el-table-column>
                </el-table>
            </div>
            <div class="customer-ranking">
                <div class="title-box">
                    <p>销售信息</p>
                    <div class="search-bar">
                        <tree-select
							placeholder="品牌"
							v-model="filter2.brandId"
							:clearable="false"
							:options="brandList"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							:key="filter2.brandId"
							@select="(node) => treeChange2(node, 'brandId')"
							:flatten-search-results="true"/>
                        <tree-select
							placeholder="系列" 
							:clearable="false"
							:options="seriesList2"
							v-model="filter2.seriesId"
							:key="filter2.seriesId"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							@select="(node) => treeChange2(node, 'seriesId')"
							:flatten-search-results="true"/>
                        <el-date-picker
                            class="date-picker-box"
                            v-model="filter2.queryDate"
                            type="datetimerange"
							:clearable="false"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="changeDate2"></el-date-picker>
                    </div>
                </div>
                <el-table :data="customerData" height="calc(100% - 42px)">
                    <el-table-column label="序号" type="index"></el-table-column>
                    <el-table-column label="产品" prop="productName" width="120"></el-table-column>
                    <el-table-column label="品牌" prop="brandName"></el-table-column>
                    <el-table-column label="系列" prop="seriesName"></el-table-column>
                    <el-table-column label="时间" prop="createTime"></el-table-column>
                </el-table>
            </div>
        </div> -->
    <!-- 运营趋势图 -->
    <div class="trend-chart-box">
      <div class="top-titles-box">
        <div class="left-title-box">
          <p
            class="t-title"
            :class="{ 'active-title': currentType === 'QUOTE' }"
            @click="chengeTitle('QUOTE')"
          >
            处理报价趋势图
          </p>
          <p
            class="t-title"
            :class="{ 'active-title': currentType === 'SALE' }"
            @click="chengeTitle('SALE')"
          >
            处理订单趋势图
          </p>
          <!-- <p class="t-title" :class="{'active-title': currentType === 'KH'}" @click="chengeTitle('KH')">联系客户趋势图</p> -->
          <!-- 联系客户趋势暂时不管 -->
        </div>
        <div class="right-title-box">
          <span class="t-search-label">处理报价</span>
          <div class="t-select-box">
            <p
              class="t-btn"
              :class="{ 'active-btn': currentDate === 'months' }"
              @click="changeQSDate('months')"
            >
              近30天
            </p>
            <p
              class="t-btn t-btn2"
              :class="{ 'active-btn': currentDate === 'quarters' }"
              @click="changeQSDate('quarters')"
            >
              季度
            </p>
            <p
              class="t-btn"
              :class="{ 'active-btn': currentDate === 'years' }"
              @click="changeQSDate('years')"
            >
              本年
            </p>
          </div>
        </div>
      </div>
      <div class="t-content">
        <div id="chart-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLetterSortData, //按字母排序
  handleTreeData, //处理树形数据
} from "@/common/js/common.js";
import { mapActions } from "vuex";
import { ElMessage } from "element-plus";
import { Chart } from "@antv/g2";
import moment from "moment";

export default {
  name: "supplierPage",
  props: {
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      currentType: "QUOTE",
      supplierData: [],
      customerData: [],
      filter: {
        brandId: null,
        seriesId: null,
        queryDate: [
          moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD HH:mm:ss"),
          moment().format("YYYY-MM-DD HH:mm:ss"),
        ],
      },
      filter2: {
        brandId: null,
        seriesId: null,
        queryDate: [
          moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD HH:mm:ss"),
          moment().format("YYYY-MM-DD HH:mm:ss"),
        ],
      },
      brandList: [],
      seriesList: [],
      seriesList2: [],
      currentDate: "months",
      chartContent: null,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.setChartContent();
    });
    // this.$nextTick(() => {
    //     setTimeout(() => {
    //         this.setChartContent()
    //     }, 1000)
    // })
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "listSkuCategoryForFirst", //顶级分类请求
      "listSkuCategoryForChildren", //获取子集分类
    ]),
    ...mapActions("homeModule", [
      "userHomeOperatingTrend", // 运营趋势
      "userHomeSaleInfo", // 销售信息
      "userHomeSupplyInfo", // 供应商信息
    ]),
    getList() {
      //请求品牌列表
      this.listSkuCategoryForFirst({
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        let { data, code } = res.data.data;
        if (code === "0" && data.length) {
          data = getLetterSortData(data, "initialCode");
          data.map((item, index) => {
            item.id = "a_" + (index + 1);
            item.label = item.letter;
            if (item.children) {
              item.children.map((son) => {
                son.label = son.categoryName;
                delete son.children;
              });
            }
          });
          //默认选中第一个
          this.brandList = data;
          this.filter.brandId = data[0].children[0].id;
          this.filter2.brandId = data[0].children[0].id;
          //根据厂家id获取系列
          this.getSeriesList("filter", true); //获取当前系列
        }
      });
      this.getUserHomeOperatingTrend();
    },
    getSeriesList(key, init) {
      // 获取系列列表
      this.listSkuCategoryForChildren({
        parentId: this[key].brandId,
      }).then((res) => {
        let { data, code } = res.data.data;
        if (code === "0" && data.length) {
          if (init) {
            this.seriesList = handleTreeData(data, "categoryName");
            this.seriesList2 = handleTreeData(data, "categoryName");
            this.filter.seriesId = this.getOneId(this.seriesList);
            this.filter2.seriesId = this.getOneId(this.seriesList);
            this.getSupplierData();
            this.getCustomerData();
          } else {
            const listKey = key === "filter" ? "seriesList" : "seriesList2";
            this[listKey] = handleTreeData(data, "categoryName");
            this[key].seriesId = this.getOneId(this.seriesList);
            if (key === "filter") {
              this.getSupplierData();
            } else {
              this.getCustomerData();
            }
          }
        }
      });
    },
    //获取第一个id
    getOneId(data, text) {
      if (data.length && data[0].children && data[0].children.length) {
        return this.getOneId(data[0].children);
      } else {
        return data[0].id;
      }
    },
    // 运营趋势查询时间切换
    changeQSDate(val) {
      this.currentDate = val;
      this.getUserHomeOperatingTrend();
    },
    chengeTitle(val) {
      this.currentType = val;
      this.getUserHomeOperatingTrend();
    },
    getUserHomeOperatingTrend() {
      // 处理报价趋势图 quoteList, 处理订单趋势图 saleList 联系客户趋势暂时不管
      const params = {
        type: this.currentType,
        startDate: moment()
          .subtract(1, this.currentDate)
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      this.userHomeOperatingTrend(params).then((res) => {
        const { data, code } = res.data;
        if (code === "0") {

          const tempData = (data && (data.quoteList || data.saleList )|| []).map((e) => ({ year: e.date, value: e.num }));


          this.chartContent.changeData(tempData)
  
        }
      });
    },
    setChartContent(data) {
      const chartBox = document.querySelector(".t-content");
      this.chartContent = new Chart({
        container: "chart-content",
        autoFit: true,
        height: chartBox.clientHeight,
        width: chartBox.clientWidth,
      });
      const tempData = data && (data.quoteList || data.saleList )|| [];

      this.chartContent.data(
        tempData.map((e) => ({ year: e.date, value: e.num }))
      );
      this.chartContent.scale({
        value: {
          min: 10000,
          nice: true,
        },
        year: {
          range: [0, 1],
        },
      });
      this.chartContent.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      this.chartContent.axis("value", {
        label: {
          formatter: (val) => {
            return (+val / 10000).toFixed(1) + "k";
          },
        },
      });

      this.chartContent.area().position("year*value");
      this.chartContent.line().position("year*value");

      this.chartContent.render();
    },
    // 供应信息 切换筛选项
    treeChange(node, type) {
      // 树形选择的事件
      this.filter[type] = node.id;
      if (type === "brandId") {
        // 厂家切换
        // console.log(node);
        this.getSeriesList("filter");
      } else {
        // this.getSupplierData
        this.filter.seriesId = node.id;
        // console.log(this.filter.seriesId);
        this.getSupplierData();
      }
    },
    changeDate() {
      if (
        this.filter.brandId ||
        this.filter.seriesId ||
        this.filter.queryDate
      ) {
        this.getSupplierData();
      }
    },
    // 拿供应信息数据
    getSupplierData() {
      // console.log(this.filter.brandId,this.filter.seriesId);
      if (this.filter.brandId || this.filter.seriesId) {
        const params = {
          brandId: this.filter.brandId,
          seriesId: this.filter.seriesId,
          startDate: this.filter.queryDate[0],
          endDate: this.filter.queryDate[1],
        };
        this.userHomeSupplyInfo(params).then((res) => {
          const { data, code } = res.data;
          // console.log(data);
          if (code === "0") {
            this.supplierData = data;
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message: "请选择查询品牌和系列",
        });
      }
    },
    // 销售信息 切换筛选项
    treeChange2(node, type) {
      this.filter2[type] = node.id;
      if (type === "brandId") {
        // 厂家切换
        // console.log(node);
        this.getSeriesList("filter2");
      } else {
        // this.getSupplierData
        this.filter2.seriesId = node.id;
        // console.log(this.filter2.seriesId);
        this.getCustomerData();
      }
    },
    changeDate2() {
      if (
        this.filter2.brandId ||
        this.filter2.seriesId ||
        this.filter2.queryDate
      ) {
        this.getCustomerData();
      }
    },
    // 拿销售数据
    getCustomerData() {
      if (this.filter2.brandId || this.filter2.seriesId) {
        const params = {
          brandId: this.filter2.brandId,
          seriesId: this.filter2.seriesId,
          startDate: this.filter2.queryDate[0],
          endDate: this.filter2.queryDate[1],
        };
        this.userHomeSaleInfo(params).then((res) => {
          const { data, code } = res.data;
          if (code === "0") {
            this.customerData = data;
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message: "请选择查询品牌和系列",
        });
      }
    },
  },
  unmounted() {
    // 销毁实例释放资源
    if (this.chartContent) {
      this.chartContent.destroy();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../style/supplier.less";
</style>
