<template>
  <div class="home">
    <!-- 总数据统计 供应商 客户共用 -->
    <div class="statistical-data" :class="{ 'gys-s-d': isSupplier }">
      <div
        class="statistical-box"
        :class="item.color"
        v-for="(item, index) in statisticalData"
        :key="index"
      >
        <div class="s-d-title">
          <i class="s-d-icon iconfont" :class="item.icon"></i>
          <p class="s-d-t">{{ item.title }}</p>
          <i class="el-icon-caret-right"></i>
        </div>
        <p class="s-d-value">
          {{ item.value }}<span class="unit">{{ item.unit }}</span>
        </p>
      </div>
    </div>
    <!-- 客户统计图表信息 供应商 -->
    <div class="data-echarts-box" v-if="isSupplier">
      <div class="d-e-box">
        <p class="d-title">客户统计</p>
        <div class="chart-box">
          <div id="chart-bar" ref="chart"></div>
        </div>
      </div>
      <div class="d-e-box">
        <p class="d-title">客户分析</p>
        <div class="chart-box chart-pie-box">
          <div class="pie-item-box chartPie1-box">
            <div id="chartPie1" ref="chart2"></div>
            <p class="chart-title">公司类型比例</p>
          </div>
          <div class="pie-item-box chartPie2-box">
            <div class="date-month">
              <el-date-picker
                v-model="queryDate"
                type="month"
                :clearable="false"
                placeholder="请选择月"
              >
              </el-date-picker>
            </div>
            <div id="chartPie2" ref="chart3"></div>
            <p class="chart-title">月采购量比例</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 工作区：代办 快捷入口 信息等 供应商 客户共用 -->
    <div class="work-area">
      <div class="w-a-box work-agency">
        <p class="w-title">代办事项</p>
        <div class="w-content">
          <div
            class="w-agency-box"
            :class="item.color"
            v-for="(item, index) in workAgency"
            :key="'wa' + index"
          >
            <div class="w-a-info">
              <p class="w-a-title">{{ item.title }}</p>
              <p class="w-a-value">{{ item.value }}</p>
            </div>
            <p class="w-a-icons">
              <i class="w-a-icon iconfont" :class="item.icon"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="w-a-box quick-entrance">
        <p class="w-title">快捷入口</p>
        <div class="w-content">
          <div class="w-c-item" @click="clickAddInquirySheet">
            <p class="icon-box blueviolet">
              <i class="iconfont iconxinzengwenshu icons"></i>
            </p>
            <p>新增询价单</p>
          </div>
          <div class="w-c-item" @click="clickOrderSetup">
            <p class="icon-box blue">
              <i class="iconfont iconshezhi2 icons"></i>
            </p>
            <p>销售中心</p>
          </div>
        </div>
      </div>
      <div class="w-a-box personal-data">
        <p class="w-title">个人资料</p>
        <div class="w-content">
          <div class="p-d-item">
            <i class="iconfont icon-yonghu"></i>
            <span>姓名</span>
            <p class="p-d-value">{{ userInfo.realName }}</p>
          </div>
          <div class="p-d-item p-d-item2">
            <i class="iconfont"></i>
            <span>编号</span>
            <p class="p-d-value">{{ userInfo.userId }}</p>
          </div>
          <div class="p-d-item">
            <i class="iconfont"></i>
            <span>等级</span>
            <p class="p-d-value">{{ userInfo.classifiedId }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 纯供应商部分 -->
    <supplier-page v-if="isSupplier" :userInfo="userInfo"></supplier-page>
    <!-- <div class="icon-show">
			<p> <i class="iconfont icon-yonghu"></i> </p>
			<p> <i class="iconfont icon-jiahao"></i> </p>
			<p> <i class="iconfont icon-shanchu"></i> </p>
			<p> <i class="iconfont icon-sousuo"></i> </p>
			<p> <i class="iconfont icon-xunjiaguanli"></i> </p>
			<p> <i class="iconfont icon-ABSbaojia"></i> </p>
			<p> <i class="iconfont icon-kehu"></i> </p>
			<p> <i class="iconfont icon-xiaoshoudingdan_icox"></i> </p>
			<p> <i class="iconfont icon-kucun"></i> </p>
			<p> <i class="iconfont icon-wodechanpin"></i> </p>
			<p> <i class="iconfont icon-shezhi"></i> </p>
			<p> <i class="iconfont icon-xianxingtubiaozhizuomoban-25"></i> </p>
			<p> <i class="iconfont icon-yunshangchuan"></i> </p>
			<p> <i class="iconfont icon-bianjishuru-xianxing"></i> </p>
			<p> <i class="iconfont icon-chakan"></i> </p>
			<p> <i class="iconfont icon-shanchu"></i> </p>
			<p> <i class="iconfont icon-gouwuche"></i> </p>
			<p> <i class="iconfont icon-bianjisekuai"></i> </p>
			<p> <i class="iconfont icon-jine"></i> </p>
			<p> <i class="iconfont icon-fahuo"></i> </p>
			<p> <i class="iconfont icon-shang"></i> </p>
			<p> <i class="iconfont icon-youjiantou"></i> </p>
			<p> <i class="iconfont icon-xiangshang"></i> </p>
			<p> <i class="iconfont icon-below-s"></i> </p>
			<p> <i class="iconfont icon-top-s"></i> </p>
			<p> <i class="iconfont icon-below-s"></i> </p>
		</div> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import supplierPage from "./components/supplierPage";
import { Chart } from "@antv/g2";
import moment from "moment";

export default {
  components: {
    supplierPage,
    // chart: ECharts
  },
  computed: {
    ...mapState("homeModule", [
      "userInfos", // 完整用户信息
    ]),
  },
  data() {
    return {
      userInfo: {},
      isSupplier: false,
      statisticalData: [],
      supplierStatisticalData: [
        {
          title: "客户总数",
          icon: "iconyonghu-bold-copy",
          value: "",
          unit: "人",
          color: "s-d-orange",
        },
        {
          title: "询价单总数",
          icon: "icon24gf-clipboardText",
          value: "",
          unit: "单",
          color: "s-d-hotpink",
        },
        {
          title: "报价单总数",
          icon: "iconjine",
          value: "",
          unit: "单",
          color: "s-d-blueviolet",
        },
        {
          title: "采购单总数",
          icon: "iconcaigoudanzongshu",
          value: "",
          unit: "单",
          color: "s-d-violet",
        },
        {
          title: "总订单数",
          icon: "iconwenjianjia",
          value: "",
          unit: "单",
          color: "s-d-turquoise",
        },
      ],
      customerStatisticalData: [
        {
          title: "供应商数",
          icon: "iconyonghu-bold-copy",
          value: "",
          unit: "家",
          color: "s-d-orange",
        },
        {
          title: "询价单总数",
          icon: "icon24gf-clipboardText",
          value: "",
          unit: "单",
          color: "s-d-hotpink",
        },
        {
          title: "采购单总数",
          icon: "iconcaigoudanzongshu",
          value: "",
          unit: "单",
          color: "s-d-violet",
        },
      ],
      workAgency: [
        {
          title: "待上传合同",
          icon: "iconwenjianshoucang2",
          value: "",
          color: "w-agency-yellow",
        },
        {
          title: "需维护订单",
          icon: "iconxiaoshou",
          value: "",
          color: "w-agency-blueviolet",
        },
        {
          title: "待发货订单",
          icon: "iconfahuodanju",
          value: "",
          color: "w-agency-green",
        },
        {
          title: "待报价询价单",
          icon: "iconxiaoshoubaojia",
          value: "",
          color: "w-agency-orange",
        },
      ],
      chartBar: null,
      chartPie1: null,
      chartPie2: null,
      queryDate: moment().format("YYYY-MM"),
      chartPie1LastItem: null,
      chartPie2LastItem: null,
      chartPie1InnerView: null,
      chartPie2InnerView: null,
      defaultColor: [
        "#fea338",
        "#fa7a82",
        "#2d8ede",
        "#48c7ec",
        "#bb7fff",
        "#00e4d5",
      ],
      // chartsPieOption: {}
    };
  },
  created() {
    if (!this.userInfos) {
      this.getUserInfo().then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.userInfo = data;
          this.setUserInfos(data);
        }
      });
    } else {
      this.userInfo = this.userInfos;
    }
    // 0表示是供应商   等于1表示是客户
    this.isSupplier = this.$store.state.userInfo.userType === "0";
    // console.log(this.userInfos, 'user')
    if (this.isSupplier) {
      this.statisticalData = this.supplierStatisticalData;
    } else {
      this.statisticalData = this.customerStatisticalData;
    }
    this.getData();
  },
  mounted() {
    if (this.isSupplier) {
      this.getChartsData();
    }
    // this.$nextTick(() => {
    // 	this.setBarCharts()
    // 	this.setPieCharts('chartPie1')
    // 	this.setPieCharts('chartPie2')
    // })
  },
  methods: {
    ...mapActions("homeModule", [
      "userHomeInfoStatistics", // 概况
      "userHomeBacklog", // 待办事项
      "userHomeCustomerAnalyse", // 客户分析
      "userHomeCustomerStatistics", // 客户统计
      // "userHomeOperatingTrend", // 运营趋势
      // "userHomeSaleInfo", // 销售信息
      // "userHomeSupplyInfo", // 供应商信息
    ]),
    ...mapActions("systemModule", [
      "getUserInfo", //请求用户信息的接口
    ]),
    ...mapMutations("homeModule", ["setUserInfos"]),
    getData() {
      // 获取概况
      this.userHomeInfoStatistics().then((res) => {
        const { code, data } = res.data;
        if (code === "0") {
          if (this.isSupplier) {
            this.statisticalData[0].value = data.customerNums;
            this.statisticalData[1].value = data.inquiryNums;
            this.statisticalData[2].value = data.quoteNums;
            this.statisticalData[3].value = data.purchaseNums;
            this.statisticalData[4].value = data.saleNums;
          } else {
            this.statisticalData[0].value = data.customerNums;
            this.statisticalData[1].value = data.inquiryNums;
            this.statisticalData[2].value = data.quoteNums;
            this.statisticalData[3].value = data.purchaseNums;
            this.statisticalData[4].value = data.saleNums;
          }
          // console.log(this.statisticalData, '概况')
        }
      });
      // 待办事项
      this.userHomeBacklog().then((res) => {
        const { code, data } = res.data;
        if (code === "0") {
          if (this.isSupplier) {
            this.workAgency[0].value = data.waitContractNum;
            this.workAgency[1].value = data.pendingDocumentNum;
            this.workAgency[2].value = data.pendingSendNum;
            this.workAgency[3].value = data.pendingQuoteNum;
          } else {
            this.workAgency[0].value = data.waitBackContractNum;
            this.workAgency[1].value = data.pendingReceiveNum;
            this.workAgency[2].value = data.pendingInquiryNum;
            this.workAgency[3].value = data.pendingPurchaseNum;
            this.workAgency[0].title = "待回传合同";
            this.workAgency[1].title = "待收货订单";
            this.workAgency[2].title = "待处理询价单";
            this.workAgency[3].title = "待处理采购单";
          }
        }
      });
    },
    getChartsData() {
      // 客户统计
      this.userHomeCustomerStatistics().then((res) => {
        const { data, code } = res.data;
        console.log(res.data, "---客户统计");
        if (code === "0") {
          // 柱状图 客户统计
          if (data.statisticsVOList && data.statisticsVOList.length > 0) {
            const chartData = [];
            const colorSet = {};
            data.statisticsVOList.forEach((item, index) => {
              colorSet[item.name] = this.defaultColor[index];
              chartData.push({ ...item });
            });
            this.setBarCharts(chartData, colorSet);
          }
        }
      });
      // 客户分析
      const params = {
        date: this.queryDate,
        userId: this.userInfo.userId,
      };
      this.userHomeCustomerAnalyse(params).then((res) => {
        const { data, code } = res.data;
        console.log(res.data, "---客户分析");
        if (code === "0") {
          // 饼图1 公司类型比例
          if (data.enterpriseTypeList && data.enterpriseTypeList.length > 0) {
            // const chartData = []
            // const colorSet = {}
            // for (const item of data.enterpriseTypeList) {
            // 	// colorSet[item.name] = this.defaultColor[index]
            // 	chartData.push({ ...item })
            // }
            this.setPieCharts(data.enterpriseTypeList, "chartPie1");
          }
          // 饼图2 月采购量比例
          if (data.purchaseList && data.purchaseList.length > 0) {
            // const chartData = []
            // const colorSet = {}
            // for (const item of data.purchaseList) {
            // 	// colorSet[item.name] = this.defaultColor[index]
            // 	chartData.push({ ...item })
            // }
            this.setPieCharts(data.purchaseList, "chartPie2");
          }
        }
      });
    },
    setBarCharts(data, colorSet) {
      // chart-bar
      const chartBox = document.querySelector(".chart-box");
      // Step 1: 创建 Chart 对象
      this.chartBar = new Chart({
        container: "chart-bar", // 指定图表容器 ID
        width: chartBox.clientWidth, // 指定图表宽度
        height: chartBox.clientHeight, // 指定图表高度
      });

      // Step 2: 载入数据源
      this.chartBar.data(data);

      // Step 3: 创建图形语法，绘制柱状图
      this.chartBar
        .interval()
        .position("name*num")
        .color("name", (value) => colorSet[value]);

      // Step 4: 渲染图表
      this.chartBar.render();
    },
    setPieCharts(data, key) {
      const chartBox = document.querySelector("." + key + "-box");

      this[key] = new Chart({
        container: key,
        autoFit: true,
        height: chartBox.clientHeight,
        width: chartBox.clientWidth,
      });
      // 新建一个 view 用来单独渲染Annotation
      this[key + "InnerView"] = this[key].createView();
      this[key].coordinate("theta", {
        radius: 0.75,
        innerRadius: 0.5,
      });

      this[key].data(data);

      // this[key].scale('percent', {
      // 	formatter: val => {
      // 		val = val * 100 + '%';
      // 		return val;
      // 	},
      // });

      this[key].tooltip(false);

      // 声明需要进行自定义图例字段： 'item'
      this[key].legend("name", {
        position: "right", // 配置图例显示位置
        custom: true, // 关键字段，告诉 G2，要使用自定义的图例
        items: data.map((obj, index) => {
          return {
            name: obj.name, // 对应 itemName
            value: obj.num, // 对应 itemValue
            marker: {
              symbol: "square", // marker 的形状
              style: {
                r: 5, // marker 图形半径
                fill: this[key].getTheme().colors10[index], // marker 颜色，使用默认颜色，同图形对应
              },
            }, // marker 配置
          };
        }),
        itemValue: {
          style: {
            fill: "#999",
          }, // 配置 itemValue 样式
          // formatter: val => `${val * 100}%` // 格式化 itemValue 内容
        },
      });

      this[key]
        .interval()
        .adjust("stack")
        .position("num")
        .color("name")
        .style({
          fillOpacity: 1,
        })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                lineWidth: 10,
                stroke: shape.attr("fill"),
                strokeOpacity: shape.attr("fillOpacity"),
              };
            },
          },
        });

      // 移除图例点击过滤交互
      this[key].removeInteraction("legend-filter");
      this[key].interaction("element-active");

      this[key].render();

      // 监听 element 上状态的变化来动态更新 Annotation 信息
      this[key].on("element:statechange", (ev) => {
        const { state, stateStatus, element } = ev.gEvent.originalEvent;

        // 本示例只需要监听 active 的状态变化
        if (state === "active") {
          const data = element.getData();
          if (stateStatus) {
            // 更新 Annotation
            this.updateAnnotation(data, key);
          } else {
            // 隐藏 Annotation
            this.clearAnnotation(key);
          }
        }
      });
    },
    // 清空 annotation
    clearAnnotation(key) {
      this[key + "InnerView"].annotation().clear(true);
      this[key + "InnerView"].render(true);
      this[key + "LastItem"] = null;
    },
    // 绘制 annotation
    updateAnnotation(data, key) {
      if (data.name !== this[key + "LastItem"]) {
        this[key + "InnerView"].annotation().clear(true);
        this[key + "InnerView"]
          .annotation()
          .text({
            position: ["50%", "50%"],
            content: data.name,
            style: {
              fontSize: 16,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetY: -20,
          })
          .text({
            position: ["50%", "50%"],
            content: data.num,
            style: {
              fontSize: 18,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetX: -10,
            offsetY: 20,
          });
        this[key + "InnerView"].render(true);
        this[key + "LastItem"] = data.name;
      }
    },
    // 跳转到 新增询价单
    clickAddInquirySheet() {
      this.$router.push({
        path: "/purchase/addInquiryPage",
      });
    },
    // 跳转到 接单设置
    clickOrderSetup() {
      this.$router.push("/mysale/orderSetup");
    },
  },
  unmounted() {
    // 销毁实例释放资源
    if (this.chartBar) {
      this.chartBar.destroy();
    }
    if (this.chartPie) {
      this.chartPie.destroy();
    }
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";
.icon-show {
  p {
    .iconfont {
      font-size: 30px;
    }
  }
}
</style>
